$(function () {

  $("#contact-form").submit(function (event) {

    event.preventDefault();

    if ($("#spambot").val() == 8) {

      $("#form-spinner").show();
      $('#form-button').prop('disabled', true);

      var actionurl = event.currentTarget.action;

      $.ajax({
          url: actionurl,
          type: 'post',
          dataType: 'text',
          data: $("#contact-form").serialize()
        })
        .done(function (data) {
          $("#form-thanks").show();
        })
        .fail(function (errMsg) {
          $("#form-error").show();
        })
        .always(function (data) {
          $("#contact-form").hide();
          $("#form-spinner").hide();
        });

    } else {
      $("#spambot").prop("style", "border-color: #dc3545;border-width:5px")
    }
  });

});